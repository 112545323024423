import { useState } from 'react';
import { getDocs, query, where } from 'firebase/firestore';
import useFirestore from './useFirestore';
import { useUser } from '../store/context/UserContext';
import { IAlbum } from '../models/IAlbum';
import { AppActionType, useApp } from '../store/context/AppContext';
import { getFunctions, httpsCallable } from '@firebase/functions';
import { StatisticsActionType, useStatisticsStore } from '../store/context/StatisticsContext';
import { DateRange } from '@mui/lab/DateRangePicker';
import moment from 'moment';

const gd = (b: number, d?: Date) => {
    const c = d ? new Date(d) : new Date();
    c.setDate(c.getDate() - b);
    return c;
};

const dbd = (a: Date, b: Date) => {
    const aD = new Date(a);
    const bD = new Date(b);
    const msPerDay = 24 * 60 * 60 * 1000;
    return Math.round(Math.abs((aD.getTime() - bD.getTime()) / msPerDay));
};

type QueryData = {
    [key: string]: any;
};
type QueryData2 = {
    [key: string]: number;
};

const useStatistics = () => {
    const [isFetching, setIsFetching] = useState(false);

    const { state, dispatch } = useStatisticsStore();
    const { collectionRef } = useFirestore();
    const {
        state: { data: user },
    } = useUser();

    const statistics = state.statistics ?? [];

    const songsListened = state.songsListened ?? [];
    const totalListened = state.totalListened ?? [];

    const storeResponseData = (response: any, from?: Date, to?: Date) => {
        const dayData: any = {};
        response.data.forEach((row: any) => {
            if (dayData[row.day] === undefined) {
                dayData[row.day] = { seconds: 0, listenings: 0, songs: [] };
            }
            dayData[row.day].seconds += Number.parseFloat(row.total_duration);
            dayData[row.day].listenings += row.count;
            dayData[row.day].songs.push(row);
        });

        // add records even if no results, so we know this has been fetched
        if (from && to) {
            const days = dbd(from, to);
            for (let i = 0; i <= days; i++) {
                const date = gd(i, new Date(to));
                const key = date.toISOString().split('T')[0];
                if (dayData[key] === undefined) {
                    dayData[key] = { seconds: 0, listenings: 0, songs: [] };
                }
            }
        }

        const newPayload = {
            ...statistics,
            ...dayData,
        };
        dispatch({
            type: StatisticsActionType.SET_STATISTICS,
            payload: newPayload,
        });
        return newPayload;
    };

    const loadAllStatistics = async () => {
        if (state.allStatsLoaded) {
            return;
        }
        setIsFetching(true);
        try {
            const response: any = await httpsCallable(
                getFunctions(),
                'httpsBigQuerySearch'
            )({
                ownerId: user?.id,
                type: 'ALL',
            });
            storeResponseData(response);
            dispatch({
                type: StatisticsActionType.ALL_STATS_LOADED,
                payload: true,
            });
        } catch (err) {
            console.log(err);
        }

        setIsFetching(false);
    };

    const loadStatistics = async (from: Date, to: Date) => {
        if (!moment(from).isValid() || !moment(to).isValid()) {
            console.error('Invalid date input');
            return;
        }

        const start = from.getTime();
        to.setDate(to.getDate() + 1);
        const end = to.getTime();

        const startKey = from.toISOString().split('T')[0];
        const endKey = to.toISOString().split('T')[0];
        // TODO: check if data has anything for that day

        let updatedStatistics = statistics;
        if (statistics[startKey] === undefined || statistics[endKey] === undefined) {
            setIsFetching(true);
            try {
                const response: any = await httpsCallable(
                    getFunctions(),
                    'httpsBigQuerySearch'
                )({
                    ownerId: user?.id,
                    from: start,
                    to: end,
                    type: 'total',
                });
                updatedStatistics = storeResponseData(response, from, to);
            } catch (err) {
                console.log(err);
            }

            setIsFetching(false);
        }

        const days = dbd(from, to);
        const dayData: any[] = [];
        const songData: any[] = [];
        const songsMap: QueryData = {};
        for (let i = 0; i <= days; i++) {
            const date = gd(i, new Date(to));
            const key = date.toISOString().split('T')[0];
            const record = updatedStatistics[key];
            const daySeconds = record ? Math.floor(record.seconds) : 0;

            dayData.push({
                date: date.toLocaleDateString(),
                seconds: daySeconds,
                listenings: record?.listenings,
            });

            record?.songs.forEach((song: any) => {
                if (songsMap[song.songId] === undefined) {
                    songsMap[song.songId] = { seconds: 0, listenings: 0, name: song.songName, inPlaylistsCount: song.stats?.inPlaylistsCount, likedCount: song.stats?.likedCount };
                }
                songsMap[song.songId].seconds += Number.parseFloat(song.total_duration);
                songsMap[song.songId].listenings += song.count;
            });
        }

        for (const [songId, song] of Object.entries(songsMap)) {
            songData.push({
                songId: songId,
                songName: song?.name,
                seconds: Math.round(song?.seconds * 100) / 100,
                listenings: song?.listenings,
                inPlaylistsCount: song?.inPlaylistsCount,
                likedCount: song?.likedCount,
            });
        }

        const sortedSongs = songData.sort((a, b) => b.seconds - a.seconds);

        dispatch({
            type: StatisticsActionType.SET_TOTAL_LISTENED,
            payload: dayData.reverse(),
        });

        dispatch({
            type: StatisticsActionType.SET_SONGS_LISTENED,
            payload: sortedSongs,
        });
    };

    return { statistics, totalListened, songsListened, loadStatistics, loadAllStatistics, isFetching };
};

export default useStatistics;
