import DragHandleIcon from '@mui/icons-material/DragHandle'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, Typography } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import { Box } from '@mui/system'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd"
import { useNavigate } from 'react-router'
import MultipleAudioFileUpload from '../../components/fields/MultipleAudioFileUpload'
import FullscreenOverlayLoader from '../../components/FullscreenOverlayLoader'
import FullscreenOverlaySubmitted from '../../components/FullscreenOverlaySubmitted'

import Navigation from '../../components/Navigation'
import useAlbums from '../../hooks/useAlbums'
import useFirestore from '../../hooks/useFirestore'
import useRecordings from '../../hooks/useRecordings'
import { useUser } from '../../store/context/UserContext'
import { getImageUrl, getSongMediaUrl, uploadImage, uploadSongFile } from '../../utils'
import { IRecordingFields, RecordingInfoForm } from '../Recordings/RecordingsCreator'
import { AlbumInfoForm, IAlbumFields } from './AlbumsCreator'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {getFunctions, httpsCallable} from "@firebase/functions";
import { config } from '../../config'

const reorder = (list: IRecordingFields[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const removeFileExtensionFromString = (filename: string) => {
    const lastDotPosition = filename.lastIndexOf(".");
    if (lastDotPosition === -1) return filename;
    else return filename.substring(0, lastDotPosition);
}

interface IDeleteAlertProps {
    index: number | null;
    title: string;
}

const AlbumsBatchCreator = () => {
    const [album, setAlbum] = useState<IAlbumFields>({});
    const [recordings, setRecordings] = useState<IRecordingFields[]>([]);
    const [albumErrors, setAlbumErrors] = useState<string[]>([]);
    const [recordingErrors, setRecordingErrors] = useState<string[][]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [albumId, setAlbumId] = useState('-1');
    const [sum, setSum] = useState(0.0);
    const [uploadInfo, setUploadInfo] = useState({submitted: 0, total: 0});
    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
    const [deleteAlert, setDeleteAlert] = useState<IDeleteAlertProps>({
        index: null,
        title: '',
    });
    const { enqueueSnackbar } = useSnackbar();
    const { add, update } = useFirestore();
    const { state: { data: user } } = useUser();
    const { fetchRecordings } = useRecordings();
    const { fetchAlbums } = useAlbums();
    const navigate = useNavigate();

    const createRecordingFromFile = (file: File) => {
        setRecordings((p) => ([...p, {
            audioFile: file,
            id: `${Math.random()}`,
            name: removeFileExtensionFromString(file.name),
        }]));
    };

    const handleInputChangePerIndex = (field: string, value: any, index: number) => {
        setRecordings((p) => {
            const newRecordings = [...p];
            newRecordings[index] = {
                ...newRecordings[index],
                [field]: value
            };
            return newRecordings;
        });
    }

    const removeRecording = (index: IDeleteAlertProps['index']) => {
        if (index == null) return;
        setRecordings((p) => {
            const newRecordings = [...p];
            newRecordings.splice(index, 1);
            return newRecordings;
        });
        deleteAlertClose();
    }

    const deleteAlertClose = () => {
        setDeleteAlert({
            index: null,
            title: '',
        });
        setIsDeleteAlertOpen(false);
    }

    const onDragEnd = (result: DropResult) => {
        // dropped outside the list
        if (!result.destination) return;

        const items = reorder(
            recordings,
            result.source.index,
            result.destination.index
        );

        setRecordings(items);
    }

    /*
        Whenever album.artists changes, this means we are adding artists to an album
        and we should add each album artist to all recordings
    */
    const albumArtists = album?.artists || [];
    useEffect(() => {
        if (albumArtists.length > 0) {
            const newRecordings = recordings.map((recording) => {
                const newArtists = recording.artists || [];

                albumArtists.forEach((artist) => {
                    if (newArtists.find((a) => a.id === artist.id)) return;
                    newArtists.push(artist);
                });

                return {
                    ...recording,
                    artists: newArtists,
                }
            });
            setRecordings(newRecordings);
        }
    }, [albumArtists]);

    /* Whenever album.recordingYear changes, set it to all recordings */
    const albumRecordingYear = album?.recordingYear ?? null;
    useEffect(() => {
        if (albumRecordingYear) {
            const newRecordings = recordings.map((recording) => {
                return {
                    ...recording,
                    recordingYear: albumRecordingYear,
                }
            });
            setRecordings(newRecordings);
        }
    }, [albumRecordingYear]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        // Generate album errors
        let albumErrors = [];
        if (!album.name) albumErrors.push('name');
        if (!album.recordingYear) albumErrors.push('recordingYear');
        if (!album.genres || album.genres.length <= 0) albumErrors.push('genres');
        if (!album.imageFile) albumErrors.push('imageFile');

        if (albumErrors.length > 0) setAlbumErrors(albumErrors);
        else setAlbumErrors([]);

        // Generate recording errors
        let recordingErrors: string[][] = [];

        const spliceIfPossible = (index: number, name: string) => {
            const prev = recordingErrors[index];
            recordingErrors[index] = prev ? [...prev, name] : [name];
        }

        recordings.forEach((recording, index) => {
            if (!recording.name) spliceIfPossible(index, 'name');
            if (!recording.recordingYear) spliceIfPossible(index, 'recordingYear');
            if (!recording.artists || recording.artists?.length <= 0) spliceIfPossible(index, 'artists');
            if (!user?.recordLabel) {
               // if (!recording.moods || recording.moods?.length <= 0) spliceIfPossible(index, 'moods');
            }
        });

        if (recordingErrors.length > 0) setRecordingErrors(recordingErrors);
        else setRecordingErrors([]);

        // Don't continue if we have errors
        if (albumErrors.length > 0 || recordingErrors.length > 0) {
            enqueueSnackbar('Please fill out all required fields', { variant: 'error' });
            return;
        }

        // At this point we are sure that we have all the data required
        setIsSubmitting(true);
        try {
            // Step 1: Create album
            const genreIds = album.genres?.map((genre) => genre.id) ?? [];

            const albumData = {
                name: album.name,
                genres: genreIds,
                artists: [],
                songs: [],
                createdAt: album?.createdAt ?? new Date(),
                img: album?.img ?? null,
                isSingle: album?.isSingle ?? false,
                recordingYear: album?.recordingYear ?? null,
                ownerId: user?.id,
                approved: album?.approved ?? false,
                availableFrom: album?.availableFrom ?? new Date()
            };

            const _album = await add('albums', albumData);

            // Step 2: Create album image
            await uploadImage(_album.id, 'albums', album.imageFile as File);
            const albumCoverUrl = getImageUrl(_album.id, 'albums', album.imageFile as File);

            // Step 3: Create songs
            const songs = await Promise.all(recordings.map(async (recording) => {
                const songData = {
                    name: recording.name,
                    artists: recording.artists?.map((artist) => artist.id) ?? [],
                    createdAt: recording?.createdAt ?? new Date(),
                    img: recording?.img ?? null,
                    url: recording?.url ?? null,
                    isrc: recording?.isrc ?? null,
                    recordingYear: recording?.recordingYear ?? null,
                    ownerId: user?.id,
                    approved: recording?.approved ?? false,
                    moods: recording?.moods ?? null,
                    explicit: recording?.explicit ?? false,
                    availableFrom: album?.availableFrom ?? new Date()
                };
                const song = await add('songs', songData);
                return song;
            }))

            setUploadInfo({submitted: 0, total: songs.length});
            // Step 4: Upload each song audio file
            await Promise.all(songs.map(async (song, i) => {
                await uploadSongFile(song.id, recordings[i].audioFile as File);
                const mediaUrl = getSongMediaUrl(song.id, recordings[i].audioFile as File);

                // Step 5: Update each song with audio file url and album cover image url
                await update('songs', song.id, { url: mediaUrl, img: albumCoverUrl });

                const newValue = uploadInfo.submitted + 1;
                setUploadInfo({total: songs.length, submitted: newValue});
            }));


            // Step 6: Update album with song ids, artist ids and image
            const artistIds = new Set(recordings?.map((recording) => recording.artists?.map((artist) => artist.id)).flat()); // create unique array of artist ids
            const songIds = songs?.map((song) => song.id) ?? [];
            await update('albums', _album.id, {
                songs: songIds,
                artists: Array.from(artistIds), // have to transform array to pure JavaScript objects because firebase doesn't accept 'new Set()'
                img: albumCoverUrl,
            });
            let total = songs.length * 5 * 1.22
            setSum(total)
            setAlbumId(_album.id)
            // Step 7: Fetch all albums and recordings
            await fetchAlbums({ force: true });
            await fetchRecordings({ force: true });

            setIsSubmitted(true)
            setIsSubmitting(false)

        //    navigate('/albums');
          //  setIsSubmitting(false);
        } catch (err) {
            enqueueSnackbar('An error occured while creating the album', { variant: 'error' });
            setIsSubmitting(false);
            return;
        }
    }

    return (
        <Box display="flex" pb={12}>
            {isSubmitting && <FullscreenOverlayLoader content="Processing audio files. This could take a few minutes." info={uploadInfo}/>}
            {isSubmitted && <FullscreenOverlaySubmitted  content="Processing audio files. This could take a few minutes." sum={sum} albumId={albumId}/>}
            <Navigation />
            <Box p={{ xs: 2, md: 4 }} maxWidth="1200px" width="100%">
                <Typography variant="h3" fontWeight="bold">Upload Album</Typography>
                <Box mt={8} maxWidth="500px" mx="auto">
                    <MultipleAudioFileUpload
                        maxSize={config.maxFileSize}
                        onChange={(files) => {
                            if (files) files?.forEach((file) => createRecordingFromFile(file))
                        }}
                    />
                    {recordings.length > 0 && <Divider sx={{ my: 4 }} />}
                    {recordings.length > 0 && (
                        <form onSubmit={handleSubmit}>
                            <Box>
                                <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>Album</Typography>
                                <AlbumInfoForm
                                    fields={album}
                                    errors={albumErrors}
                                    includeSongsAutocomplete={false}
                                    includeArtistsAutocomplete={true}
                                    artistsAutocompleteHelperText="You can add featured artists separately on each recording"
                                    setFields={(name, value) => setAlbum((p) => ({ ...p, [name]: value }))}
                                />
                            </Box>

                            <Divider sx={{ my: 4 }} />

                            <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>Recordings</Typography>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={{
                                                width: '100%'
                                            }}
                                        >
                                            {recordings.map((recording, index) => {
                                                return (
                                                    <Draggable key={recording.id} draggableId={recording.id as string} index={index}>
                                                        {(provided, snapshot) => (
                                                            <Accordion
                                                                defaultExpanded={index === 0}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                sx={{
                                                                    backgroundColor: snapshot.isDragging ? '#F9F9F9' : null,
                                                                    border: recordingErrors[index] && '1px solid red',
                                                                }}
                                                            >
                                                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                                    <Box {...provided.dragHandleProps} sx={{ width: 36, height: 24 }}>
                                                                        <DragHandleIcon color="action" />
                                                                    </Box>
                                                                    <Typography sx={{ display: 'flex', alignItems: 'center' }}>{recording.name}</Typography>
                                                                    <IconButton
                                                                        sx={{ ml: 'auto', mr: 2 }}
                                                                        size="small"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            setDeleteAlert({
                                                                                index: index,
                                                                                title: recording?.name ?? ''
                                                                            });
                                                                            setIsDeleteAlertOpen((p) => !p)
                                                                        }}>
                                                                        <DeleteForeverIcon sx={{ width: 20, height: 20 }} />
                                                                    </IconButton>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <RecordingInfoForm
                                                                        fields={recordings[index]}
                                                                        errors={recordingErrors[index]}
                                                                        setFields={(name, value) => {
                                                                            handleInputChangePerIndex(name, value, index)
                                                                        }}
                                                                    />
                                                                    {recording.audioFile && (
                                                                        <Box display="flex" flexDirection="column" my={2}>
                                                                            <Typography variant="caption" sx={{ color: 'text.secondary' }}>Audio</Typography>
                                                                            <Box mt={1} />
                                                                            <audio controls src={URL.createObjectURL(recording.audioFile)} style={{ maxWidth: 220, maxHeight: 40 }} />
                                                                        </Box>
                                                                    )}
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        )}
                                                    </Draggable>
                                                )
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            <Box mt={4} display="flex" justifyContent="center" alignItems="center">
                                <Button variant="contained" color="primary" type="submit">Submit</Button>
                            </Box>
                        </form>
                    )}
                    {recordings.length > 0 && <Box height="300px" />}
                </Box>
            </Box>
            <Dialog
                open={isDeleteAlertOpen}
                onClose={deleteAlertClose}
                maxWidth="xs"
            >
                <DialogTitle>
                    Remove {deleteAlert.title ?? 'recording'}?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You cannot undo this action but you can add this recording again later.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={deleteAlertClose}>Cancel</Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => removeRecording(deleteAlert.index)}
                        autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default AlbumsBatchCreator
